import {Route, Routes, Navigate} from 'react-router-dom';
import Navbar from './components/Navbar'
import Dashboard from './components/Dashboard';
import SignUp from './components/SignUp/index.jsx';
import LogIn from './components/LogIn';
import Products from './components/Products';
import Monitor from './components/Monitor';
import Account from './components/Account';
import Billing from './components/Billing';
import Support from './components/Support';

function App() {
  const user = localStorage.getItem("token");
  return (
    <>
    <Routes>
      {user && <Route path="/" element={<Navbar/>} >
        <Route path='/' exact element={<Dashboard/>}/>
        <Route path='/products' exact element={<Products/>}/>
        <Route path='/monitor' exact element={<Monitor/>}/>
        <Route path='/account' exact element={<Account/>}/>
        <Route path='/billing' exact element={<Billing/>}/>
        <Route path='/support' exact element={<Support/>}/>
      </Route>}
      <Route path='/signup' exact element={<SignUp/>}/>
      <Route path='/' exact element={<Navigate replace to="/login"/>}/>
      <Route path='/login' exact element={<LogIn/>}/>
    </Routes>
    </>
  );
}

export default App;
