import "./styles.scss";

const Account = () => {

    return (
        <div className="container">
            Account
        </div>
    )
}

export default Account;