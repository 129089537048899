import "./styles.scss";
import axios from 'axios';
import { useState } from 'react';
import { Toast } from 'bootstrap'
import { Link } from "react-router-dom";
import Left from "../../imgs/left.png"

const LogIn = () => {
    
    const [data, setData] = useState({
        email: "",
        password: ""
    });

    const handleChange = ({currentTarget: input}) => {
        setData({...data, [input.name]: input.value});
    }

    const toastTrigger = document.getElementById('liveToastBtn')
    const toastLiveExample = document.getElementById('liveToast')
    if (toastTrigger) {
    toastTrigger.addEventListener('click', () => {
        const toast = new Toast(toastLiveExample)
        toast.show()
    })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = "https://back.canopyrankings.com/api/auth";
            const {data: res} = await axios.post(url, data);
            localStorage.setItem("token", res.data);
            window.location = "/"
        } catch (error){
            console.log(error.response.status);
            if (error.response && error.response.status >= 400 && error.response.status <= 500) {
                const toast = new Toast(toastLiveExample)
                toast.show()
                console.log(error);
            }
        }
    }

    return (
        <div className="flex-container">

  
  <div className="flex-child">
    <img className="left-image center"src={Left}/>
  </div>

  <div className="flex-child">
    <div className="login-form">
    <form className="form-conatiner">
    <h3>Log In</h3>
    <p className="text-muted mb-4">Welcome Back.</p>
    <div className="form-group mb-3">
        <label for="email">Email</label>
        <input type="email" className="form-control mt-2" id="email" name="email" onChange={handleChange} value = {data.email} required placeholder="noname@gmail.com"/>
    </div>
    <div className="form-group mb-3">
        <label for="password">Password</label>
        <input type="password" className="form-control mt-2" id="password" name="password" onChange={handleChange} value = {data.password} required placeholder="password"/>
        <p className="form-text text-muted forgot">Forgot Password?</p>
    </div>

    <div className="form-group mb-3">
        <div className="row justify-content-between">
            <div className="col-5 p-0">
            <   hr className="hr"/>
            </div>
            <div className="col-2 p-0">
                <p className="or-set">or</p>
            </div>
                <div className="col-5 p-0">
            <hr className="hr"/>
        </div>
    </div>
    </div>

    <div className="google-container row justify-content-center">
        <div className="test">
        
        <div className="form-group mb-3">
            <div id="g_id_onload"
                data-client_id="85414190333-vm39j3oedc9ctcj99hjaaa07n10aov4t.apps.googleusercontent.com"
                data-callback="handleCredentialResponse"
                data-auto_prompt="true">
            </div>
            <div className="g_id_signin"
                data-type="standard"
                data-size="large"
                data-theme="outline"
                data-text="continue_with"
                data-shape="rectangular"
                data-logo_alignment="left">
            </div>
        </div>
    
        </div>
    </div>
    
    <button type="submit" className="btn btn-login mb-3 p-2 mt-5" onClick={handleSubmit}>Login</button>
    <div>
        <div className="row justify-content-between">
            <div className="col-8">
            <small id="emailHelp" className="form-text text-muted"><em>Don't have an account?</em></small>
            </div>
            <div className="col-4">
            <p className="forgot"><Link to="/signup"><em>Sign Up</em></Link></p>
            </div>
        </div>
    </div>
    </form>
    </div>
  </div>
  
</div>
    )
}

export default LogIn;