import "./styles.scss";
import axios from 'axios';
import search from '../../imgs/search.svg'
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import amazonLogo from "../../imgs/amazon.png"
import meliLogo from "../../imgs/meli.png"

const Monitor = () => {

    const [data, setData] = useState()
    const [numbers, setNumbers] = useState();
    const [current, setCurrent] = useState(1);
    const [searching, setSearching] = useState(false);

    const [paginatedData, setPaginatedData] = useState();

    const [keyWord, setKeyWord] = useState()

    const [marketplace, setMarketplace] = useState();
    const [market, setMarket] = useState();

    const changePage = (goto) => {
        if (current !== goto && goto <= numbers.length && goto > 0) {
          setCurrent(goto)
          setPaginatedData(data.slice((goto*10-10),(goto*10)));
        }
      }

    const handleSearch = async (e) => {
        e.preventDefault();

        setSearching(true)

        if (keyWord && marketplace && marketplace === "Amazon") {
            try {
                const url = `https://swlzie10l7.execute-api.us-east-1.amazonaws.com/default/Canopy_Rankings_Amazon_py38?param1=${keyWord}`;
                const res = await axios.get(url);
            } catch (error){
                console.log(error);
            }
        }

        else if (keyWord && marketplace && marketplace === "Meli") {
            try {
                const url = `https://t0gj3vtvv6.execute-api.us-east-1.amazonaws.com/default/MELI_API_Canopy?param1=${keyWord}`;
                const res = await axios.get(url);
            } catch (error){
                console.log(error);
            }
        }

        if (!marketplace) {
            toast.warn('Please Select a Marketplace', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            console.log("please seect a marketplace")
            setSearching(false)
        }
        
        else if (!keyWord) {
            setSearching(false)
            toast.warn('Please Enter a Keyword', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }

        else {
            try {
                const url = "https://back.canopyrankings.com/api/aws/get-products";
                const res = await axios.get(url, {params: {keyword: keyWord, market: marketplace} });
    
                console.log(res.data);
                setData(res.data.slice(1));
    
                let tmpNumbers = [];
                for (let i = 0; i < res.data.length / 10; i++) {
                  tmpNumbers.push(i+1)
                }
                console.log(tmpNumbers)
                setNumbers(tmpNumbers)
                setPaginatedData(res.data.slice(1,11));
    
                console.log(paginatedData)
    
                console.log(res.data)
                setSearching(false)
                setMarket(marketplace)
            }
            catch(error) {
                console.log(error);
                setSearching(false)
            }
        }

    }

    return (
        <div className="container">
            <ToastContainer />
            <div className="row justify-content-end my-3">
                <div className="col-2">
                <i className="fas fa-regular fa-life-ring"></i><span>Help</span>
                </div>
                <div className="col-2">
                <i className="fas fa-regular fa-cog"></i><span>Setting</span>
                </div>
            </div>
            <div className="">
              <h3 className="">Keyword Monitor</h3>
            </div>

            <div className="filter-base">
            <div className="my-3 filter">

            <div class="dropdown">
            <select class="form-select" aria-label="Default select example" value={marketplace} onChange={e => setMarketplace(e.target.value)} >
            <option disabled selected>Select your Marketplace</option>
            <option value="Amazon" >Amazon</option>
            <option value="Meli" >Mercado Libre</option>
            </select>

</div>


            <div>
            <input value={keyWord} onChange={e => setKeyWord(e.target.value)} type="search" class="form-control seacrh-input" placeholder="Enter a keyword to see its product ranking" aria-label="Search"/>
            </div>
            <div>
            <button className="btn filer-btn" onClick={handleSearch}>{searching ? <i class="fa fa-circle-o-notch fa-spin"></i> : <i class="fa fa-search" aria-hidden="true"></i>}</button>
            </div>
            <div>
            <button className="btn filer-btn">Save this Keyword</button>
            </div>

            </div>
            </div>

            <div className="table-container">
            <table className="table table-hover">
            <thead>
                <tr className="table-head">
                    <th scope="col" className="ths">Rank</th>
                    <th scope="col" className="thm">Title</th>
                    <th scope="col" className="thm">Price</th>
                    <th scope="col" className="thm">URL</th>
                    <th scope="col" className="thm">Lisitng Type</th>
                    <th scope="col" className="the">Seller</th>
                </tr>
                </thead>
            <tbody>
                {paginatedData && market === "Meli" && paginatedData.map((item, index) => (
                    <>
                    <tr>
                    <td>{index+1}</td>
                    <td>{item[1].length > 35 ? (item[1].slice(0,50) + "...") : item[1]}</td>
                    <td>{"$"+item[2]}</td>
                    <td><a href={item[3]} target="_blank" rel="noopener noreferrer">View Product</a></td>
                    <td>{item[4]}</td>
                    <td>{item[5]}</td>
                </tr>
                </>
                ))}
                {paginatedData && market === "Amazon" && paginatedData.map((item, index) => (
                    <>
                    <tr>
                    <td>{index+1}</td>
                    <td>{item[2].length > 35 ? (item[2].slice(0,50) + "...") : item[2]}</td>
                    <td>{
                        item.filter(data => data[0] === "$")[1]
                        }</td>
                    <td><a href={item[4]} target="_blank" rel="noopener noreferrer">View Product</a></td>
                    <td>{item[7] ? "Prime" : "None"}</td>
                    <td>{item[3]}</td>
                </tr>
                </>
                ))}
            </tbody>
            </table>
            <div className="pages">
              <span onClick={() => changePage(current - 1)} className="paginator">-</span>
              {numbers && numbers.map((item) => (
                <span onClick={() => changePage(item)} className="paginator">{item}</span>
              ))}
              <span onClick={() => changePage(current + 1)} className="paginator">+</span>
            </div>
            </div>
        </div>
    )
}

export default Monitor;