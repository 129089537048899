
const Dashboard = () => {

    return (
        <div>
            Bienvenido
        </div>
    )
}

export default Dashboard;