import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import { useState } from 'react';
import Left from "../../imgs/left.png"

const SignUp = () => {
    const [data, setData] = useState({
        name: "",
        email: "",
        password: ""
    });

    const [error, setError] = useState("")
    const navigate = useNavigate();

    const handleChange = ({currentTarget: input}) => {
        setData({...data, [input.name]: input.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(data);
        try {
            const url = process.env.API_URL + "/api/users";
            const {data: res} = await axios.post(url, data);
            navigate("/login")
            console.log(res.message);
        } catch (error){
            if (error.response && error.response.status >= 400 && error.response.status <= 500) {
                setError(error.response.data.message)
            }
        }
    }
    return (
        <div className="flex-container">

        
        <div className="flex-child">
        <img className="left-image center"src={Left}/>
        </div>

        <div className="flex-child">
        <div className="login-form">
        <form className="form-conatiner" onSubmit={handleSubmit}>
        <h3>Register</h3>
        <p className="text-muted mb-4">Welcome Back.</p>
        <div className="form-group mb-3">
            <label for="name">Name</label>
            <input type="text" className="form-control mt-2" id="name" name="name" onChange={handleChange} value = {data.name} required placeholder="name"/>
        </div>
        <div className="form-group mb-3">
            <label for="email">Email</label>
            <input type="email" className="form-control mt-2" id="email" name="email" onChange={handleChange} value = {data.email} required placeholder="noname@gmail.com"/>
        </div>
        <div className="form-group mb-3">
            <label for="password">Password</label>
            <input type="password" className="form-control mt-2" id="password" name="password" onChange={handleChange} value = {data.password} required placeholder="password"/>
        </div>
        <div className="form-group mb-3">
            <label for="password">Confirm Password</label>
            <input type="password" className="form-control mt-2" id="password" name="password" onChange={handleChange} value = {data.password} required placeholder="password"/>
        </div>

        {error && <div>{error}</div>}
        
        <button type="submit" className="btn btn-login mb-3 p-2 mt-5">Register</button>
        <div>
            <div className="row justify-content-between">
                <div className="col-8">
                <small id="emailHelp" className="form-text text-muted"><em>Already have an account?</em></small>
                </div>
                <div className="col-4">
                <p className="forgot"><Link to="/login"><em>Log In</em></Link></p>
                </div>
            </div>
        </div>
        </form>
        </div>
        </div>

        </div>
    )
}

export default SignUp