import { Link, Outlet } from "react-router-dom";
import { googleLogout } from '@react-oauth/google';
import "./styles.scss";
import { useEffect, useState } from "react";
import Logo from "../../imgs/WHITE V2.png"

const Navbar = () => {
    const handleLogout = () => {
        googleLogout();
        localStorage.removeItem("token");
        window.location.assign("/login");

    }

    const loadTab = () => {
        let ele = document.getElementById(window.location.pathname.split('/')[1]);
        if(ele) {ele.classList.add('active')}
      }

    useEffect(() => {
      loadTab();
    }, [])
    

    // const [path, setPath] = useState(window.location.pathname)


    const handleNavigation = () => {
        const menu_links = Array.from(document.getElementsByClassName("link"))
        menu_links.forEach(link => link.classList.remove("active"))

        loadTab();
    }

    return (
        <>
        <nav>
            <ul className="ul" onClick={handleNavigation}>
                <li id="products" className="link my-2">
                    <Link className="linkto" to ="/products">
                    <div className="row icon-container justify-content-between">
                        <div className="col-8 icons">
                        <p className="m-0">My Products</p>
                        </div>
                        <div className="col-2 icons">
                        <i className="fas fa-regular fa-shopping-bag"></i>
                        </div>
                    </div>
                    </Link>
                </li>

                <li id="monitor" className="link my-2">
                    <Link className="linkto" to ="/monitor">
                    <div className="row icon-container justify-content-between">
                        <div className="col-8 icons">
                        <p className="m-0">Key Monitor</p>
                        </div>
                        <div className="col-2 icons">
                        <i className="fas fa-regular fa-search"></i>
                        </div>
                    </div>
                    </Link>
                </li>

                <li id="account" className="link my-2">
                    <Link className="linkto" to ="/account">
                    <div className="row icon-container justify-content-between">
                        <div className="col-8 icons">
                        <p className="m-0">My Account</p>
                        </div>
                        <div className="col-2 icons">
                        <i className="fas fa-regular fa-user"></i>
                        </div>
                    </div>
                    </Link>
                </li>

                <li id="billing" className="link my-2">
                    <Link className="linkto" to ="/billing">
                    <div className="row icon-container justify-content-between">
                        <div className="col-8 icons">
                        <p className="m-0">Billing</p>
                        </div>
                        <div className="col-2 icons">
                        <i className="fas fa-regular fa-credit-card"></i>
                        </div>
                    </div>
                    </Link>
                </li>

                <li id="support" className="link my-2">
                    <Link className="linkto" to ="/support">
                    <div className="row icon-container justify-content-between">
                        <div className="col-8 icons">
                        <p className="m-0">Support</p>
                        </div>
                        <div className="col-2 icons">
                        <i className="fas fa-regular fa-headphones"></i>
                        </div>
                    </div>
                    </Link>
                </li>



                <li className="go-bottom"><img className="long-logo" src={Logo} alt="Long Logo"/></li>
            
        

                {/*<li className="go-bottom">
                    <button type="" onClick={handleLogout} className="btn mb-4"><i className="fas fa-regular fa-power-off"></i></button>
                </li>*/}


            </ul>
        </nav>
        <div className="containerb">
            <Outlet/>
        </div>
        </>
    )
}

export default Navbar;