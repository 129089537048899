import "./styles.scss";
import VisaLogo from "../../imgs/VISA-Logo.png";
import MasterLogo from "../../imgs/logo-Mastercard.png";

const Billing = () => {

    return (
        <div className="mx-5 details">
            <div className="row justify-content-end my-3">
                <div className="col-2">
                <i className="fas fa-regular fa-life-ring"></i><span> Help</span>
                </div>
                <div className="col-2">
                <i className="fas fa-regular fa-cog"></i><span> Setting</span>
                </div>
            </div>
            <div className="row mb-5">
            <h2>Billing</h2>
            </div>

            <div className="row">
            <h3>Payment Method</h3>
            <p>Update your billing details and address</p>
            </div>

            <hr/>

            <div>
            <div class="row">
                <h3>Contact Email</h3>

                <div class="col-4">
                <p>Where should invoices be sent?</p>
                </div>
                <div class="col-4">

                    <div class="mb-3">
                        <input type="email" class="border-1 rounded" id="exampleInputEmail1" aria-describedby="emailHelp" style={{width: "100%"}} />
                    </div>
                    <div class="mb-3">
                        <input type="password" class="border-1 rounded" id="exampleInputPassword1" style={{width: "100%"}} />
                    </div>

                </div>

                <div class="col-4">

                    <div class="mb-3">
                        <p>Change email</p>
                    </div>
                    <div class="mb-3">
                        <p>Change password</p>                    
                    </div>

                </div>
            </div>
            </div>
            
            <hr/>
            
            <div>
            <div class="row">
                <div class="col-4">

                    <h3>Card Details</h3>
                    <p>Select a payment method</p>
                
                </div>
                <div class="col-8">

                <div class="card mb-3" style={{"max-width": "540px"}}>
                <div class="row g-0 border-1 rounded" style={{"border-color": "#1E4D61 !important", "border-style": "solid"}}>
                    <div class="col-md-4">
                    <img src={VisaLogo} class="img-fluid rounded-start p-5" alt="..."/>
                    </div>
                    <div class="col-md-8" style={{display: "flex", "align-items": "center", "justify-content": "center"}}>
                    <div class="card-body" style={{display: "table", margin: "0 auto"}}>
                        <p class="card-text">Visa ending in 2346</p>
                        <p class="card-text">Expiry 06/2024</p>
                    </div>
                    </div>
                </div>
                </div>

                <div class="card mb-3" style={{"max-width": "540px"}}>
                <div class="row g-0 border-1 rounded" style={{"border-color": "#1E4D61 !important", "border-style": "solid"}}>
                    <div class="col-md-4">
                    <img src={MasterLogo} class="img-fluid rounded-start p-5" alt="..."/>
                    </div>
                    <div class="col-md-8" style={{display: "flex", "align-items": "center", "justify-content": "center"}}>
                    <div class="card-body" style={{display: "table", margin: "0 auto"}}>
                        <p class="card-text">Mastercard ending in 7654</p>
                        <p class="card-text">Expiry 02/2024</p>
                    </div>
                    </div>
                </div>
                </div>

                </div>
            </div>
            </div>

        </div>
    )
}

export default Billing;