import "./styles.scss";

const Support = () => {

    return (
        <div className="container">
            Support
        </div>
    )
}

export default Support;