import "./styles.scss";
import axios from 'axios';
import { useEffect, useState, useRef} from "react";
import uploadImg from '../../imgs/upload.svg'
import doneImg from '../../imgs/done.svg'
import Dropzone from 'react-dropzone'
import { Link } from "react-router-dom";
import Papa from "papaparse";


const Products = () => {

    const [data, setData] = useState()
    const [numbers, setNumbers] = useState();
    const [current, setCurrent] = useState(1);

    const [linkUpload, setLinkUpload] = useState("");

    const upload = useRef()
    const load = useRef()
    const loadOne = useRef()
    const done = useRef()
    const pather = useRef()
    const uploader = useRef()
    

    let datas = []

    const [paginatedData, setPaginatedData] = useState();

    const loadP = async () => {
      const url = "https://back.canopyrankings.com/api/aws/list-products";
      const res = await axios.get(url);

      console.log(res.data.slice(1));
      let baseData = res.data.slice(1);
      setData(baseData);

      let tmpNumbers = [];
      for (let i = 0; i < baseData / 10; i++) {
        tmpNumbers.push(i+1)
      }
      console.log(tmpNumbers)
      setNumbers(tmpNumbers)
      setPaginatedData(baseData);
      return 0;
  }

    useEffect(() => {
        loadP();
    }, [])
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      upload.current.style.display="none";
      loadOne.current.style.display="block";

      uploader.current.style.display="none";
      pather.current.style.display="flex";
      try {
        const url = `https://yms2o8g730.execute-api.us-east-1.amazonaws.com/default/My_products?param1=${linkUpload}&param2=${2}`;
        let config = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Max-Age": "1800",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE",
            "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,x-requested-with",
            "X-Requested-With": "*"
          }
        }
        const res = await axios.get(url, config);
        console.log(res);
      } catch (error){
          console.log(error);
      }
      loadP().then(() => {
        loadOne.current.style.display="none";
        done.current.style.display="block";
      });
    }

    const changePage = (goto) => {
      if (current !== goto && goto <= numbers.length && goto > 0) {
        setCurrent(goto)
        setPaginatedData(data.slice((goto*10-10),(goto*10)));
      }
    }

    const delay = ms => new Promise(
      resolve => setTimeout(resolve, ms)
    );

    const handleDrop = async (file) => {
      console.log(file)

      file.forEach(ele => {
        Papa.parse(ele, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            results.data.forEach(link => {
              console.log(link)
            }) 
          },
        });
      });
      
      upload.current.style.display="none";
      load.current.style.display="block";

      uploader.current.style.display="none";
      pather.current.style.display="flex";

      await delay(3000);

      load.current.style.display="none";
      done.current.style.display="block";
    }

    const restartModal = () => {
      upload.current.style.display="block";
      load.current.style.display="none";
      loadOne.current.style.display="none";
      done.current.style.display="none";

      uploader.current.style.display="flex";
      pather.current.style.display="none";
    }
    

    return (
        <div className="container">
            <div className="row justify-content-end my-3">
                <div className="col-2">
                <i className="fas fa-regular fa-life-ring"></i><span> Help</span>
                </div>
                <div className="col-2">
                <i className="fas fa-regular fa-cog"></i><span> Setting</span>
                </div>
            </div>
            <div className="row my-3">
            
              <div className="col-3">
              <h3 className="">Your Product List</h3>
              </div>

              <div className="dropdown col-9">
              <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Select your Marketplace
              </button>
              <ul className="dropdown-menu">
                  <li><button className="dropdown-item" type="button">Action</button></li>
                  <li><button className="dropdown-item" type="button">Another action</button></li>
                  <li><button className="dropdown-item" type="button">Something else here</button></li>
              </ul>
              </div>

            </div>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body">
                <div class="row align">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                <div class="col upload-side">
                  <div className="text-center">
                  <div className="upload-text"> Link Upload </div>
                  <input type="text" class="form-control my-4" id="linkupload" name="linkupload" onChange={e => setLinkUpload(e.target.value)} value={linkUpload} required/>
                  <button type="button" class="btn btn-continue" onClick={handleSubmit}>Add Product</button>
                  </div>
                </div>
                <Dropzone onDrop={acceptedFiles => handleDrop(acceptedFiles)}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} class="col upload-side bulk-section bulk-section-pass" ref={uploader}>
                          <input {...getInputProps()} />
                          <div>
                            <div className="text-center" ref={upload}> 
                              <p className="upload-text">Get started by uploading your products URLs list</p>   
                              <img src={uploadImg} alt="Upload" />
                            </div>
                        </div>
                        </div>
                    )}
                  </Dropzone>
                  <div class="col upload-side bulk-section" ref={pather} style={{display: "none"}}>
                    <div>
                    <div className="text-center" ref={load} style={{display: "none"}}> 
                      <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      <p className="upload-text">Products are Uploading</p>   
                      <p className="upload-text-sub">This may take a minute</p>   
                    </div>

                    <div className="text-center" ref={loadOne} style={{display: "none"}}> 
                      <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      <p className="upload-text">Uploading Product</p>   
                      <p className="upload-text-sub">This may take a minute</p>   
                    </div>

                    <div className="text-center" ref={done} style={{display: "none"}}> 
                      <img src={doneImg} style={{width: "100px"}} alt="Done"/>
                      <p className="done-text">Done!</p> 
                      <Link to="/signup"></Link>  
                      <button type="button" data-bs-dismiss="modal" class="btn btn-continue">See Products</button>
                    </div>

                    </div>
                  </div>
              </div>
                </div>
              </div>
            </div>
          </div>

            <div className="row justify-content-end">
                <div className="col-2">
                <button type="button" class="btn " data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={restartModal}>
                <i className="fas fa-regular fa-plus-circle add-product-icon"></i><span className="add-product-txt"> Add Product</span>
                </button>
                </div>
            </div>
            <div className="table-container">
            <table className="table table-hover">
            <thead>
                <tr className="table-head">
                    <th scope="col" className="ths">Image</th>
                    <th scope="col" className="thm">Title</th>
                    <th scope="col" className="thm">Price</th>
                    <th scope="col" className="thm">URL</th>
                    <th scope="col" className="the">Marketplace</th>
                </tr>
                </thead>
            <tbody>
                {paginatedData && paginatedData.map((item) => (
                    <>
                    <tr>
                    <td scope="row"><img className="table-image" src={item[1]}/></td>
                    <td>{item[2].length > 35 ? (item[2].slice(0,50) + "...") : item[2]}</td>
                    <td>{"$" + item[3]}</td>
                    <td><a href={item[4]}>View Product</a></td>
                    <td>Mercado Libre</td>
                </tr>
                </>
                ))}
            </tbody>
            </table>
            <div className="pages">
              <span onClick={() => changePage(current - 1)} className="paginator">-</span>
              {numbers && numbers.map((item) => (
                <span onClick={() => changePage(item)} className="paginator">{item}</span>
              ))}
              <span onClick={() => changePage(current + 1)} className="paginator">+</span>
            </div>
            </div>
        </div>
    )
}

export default Products;